// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SearchBox_fixedWrapper__gMptC {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 75px;
  transition-delay: 2s;
  background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.6588235294));
}
.SearchBox_fixedWrapper__gMptC .SearchBox_top__gsBdY {
  top: 54px;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi {
  background-color: #f2f2f2;
  width: 350px;
  float: right;
  padding: 20px;
  border-radius: 5px;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi .SearchBox_head__at9Ip {
  position: relative;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi .SearchBox_head__at9Ip input {
  background-color: white;
  padding: 10px 20px;
  border: 0;
  border-radius: 5px;
  width: 100%;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi .SearchBox_head__at9Ip button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi .SearchBox_list__9mfUL ul {
  padding: 0;
  margin-top: 15px;
  max-height: 210px;
  overflow-y: scroll;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi .SearchBox_list__9mfUL ul li {
  list-style: none;
  margin-bottom: 10px;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi .SearchBox_list__9mfUL ul li:last-child {
  margin-bottom: 0;
}
.SearchBox_fixedWrapper__gMptC .SearchBox_searchWrapper__0SC4l .SearchBox_searchBox__Lmcbi .SearchBox_list__9mfUL ul .SearchBox_keySearches__oF0fN {
  font-size: 14px;
  font-weight: bold;
  color: #113069;
}`, "",{"version":3,"sources":["webpack://./src/components/SearchBox/SearchBox.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,SAAA;EACA,oBAAA;EAKA,2EAAA;AAHF;AAAE;EACE,SAAA;AAEJ;AAEI;EACE,yBAAA;EACA,YAAA;EAEA,YAAA;EACA,aAAA;EACA,kBAAA;AADN;AAEM;EACE,kBAAA;AAAR;AACQ;EACE,uBAAA;EACA,kBAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;AACV;AACQ;EACE,kBAAA;EACA,SAAA;EACA,WAAA;AACV;AAGQ;EACE,UAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;AADV;AAEU;EACE,gBAAA;EACA,mBAAA;AAAZ;AACY;EACE,gBAAA;AACd;AAEU;EACE,eAAA;EACA,iBAAA;EACA,cAAA;AAAZ","sourcesContent":[".fixedWrapper {\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  z-index: 1001;\n  top: 75px;\n  transition-delay: 2s;\n\n  .top {\n    top: 54px;\n  }\n  background-image: linear-gradient(transparent, #000000a8);\n  .searchWrapper {\n    .searchBox {\n      background-color: #f2f2f2;\n      width: 350px;\n      // height: 300px;\n      float: right;\n      padding: 20px;\n      border-radius: 5px;\n      .head {\n        position: relative;\n        input {\n          background-color: white;\n          padding: 10px 20px;\n          border: 0;\n          border-radius: 5px;\n          width: 100%;\n        }\n        button {\n          position: absolute;\n          top: 10px;\n          right: 10px;\n        }\n      }\n      .list {\n        ul {\n          padding: 0;\n          margin-top: 15px;\n          max-height: 210px;\n          overflow-y: scroll;\n          li {\n            list-style: none;\n            margin-bottom: 10px;\n            &:last-child {\n              margin-bottom: 0;\n            }\n          }\n          .keySearches {\n            font-size: 14px;\n            font-weight: bold;\n            color: #113069;\n          }\n        }\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fixedWrapper": `SearchBox_fixedWrapper__gMptC`,
	"top": `SearchBox_top__gsBdY`,
	"searchWrapper": `SearchBox_searchWrapper__0SC4l`,
	"searchBox": `SearchBox_searchBox__Lmcbi`,
	"head": `SearchBox_head__at9Ip`,
	"list": `SearchBox_list__9mfUL`,
	"keySearches": `SearchBox_keySearches__oF0fN`
};
export default ___CSS_LOADER_EXPORT___;
