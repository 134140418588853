// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page_pageContainer__wdmvs {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.page_pageContainer__wdmvs .page_imgWrapper__87qQh {
  max-width: 400px;
  margin-bottom: 20px;
}
.page_pageContainer__wdmvs .page_imgWrapper__87qQh img {
  width: 100%;
}
.page_pageContainer__wdmvs .page_contentWrapper__JQgPE {
  text-align: center;
}
.page_pageContainer__wdmvs .page_contentWrapper__JQgPE h6 {
  font-size: 80px;
  font-weight: 900;
  color: #1b4e9b;
  margin: 0;
  font-family: sans-serif;
}
.page_pageContainer__wdmvs .page_contentWrapper__JQgPE p {
  font-size: 30px;
  font-weight: 500;
  color: #000;
  font-family: sans-serif;
}
.page_pageContainer__wdmvs .page_contentWrapper__JQgPE button {
  background-color: #bccf31;
  border-radius: 5px;
  border: none;
  color: #fff;
  padding-inline: 25px;
  padding-block: 8px;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
}`, "",{"version":3,"sources":["webpack://./src/containers/PageNotFound/page.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;AACF;AACE;EACE,gBAAA;EACA,mBAAA;AACJ;AAAI;EACE,WAAA;AAEN;AAEE;EACE,kBAAA;AAAJ;AACI;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,SAAA;EACA,uBAAA;AACN;AACI;EACE,eAAA;EACA,gBAAA;EACA,WAAA;EACA,uBAAA;AACN;AACI;EACE,yBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,oBAAA;EACA,kBAAA;EACA,uBAAA;EACA,eAAA;EACA,gBAAA;AACN","sourcesContent":[".pageContainer {\n  width: 100%;\n  height: 100vh;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  flex-direction: column;\n\n  .imgWrapper {\n    max-width: 400px;\n    margin-bottom: 20px;\n    img {\n      width: 100%;\n    }\n  }\n\n  .contentWrapper {\n    text-align: center;\n    h6 {\n      font-size: 80px;\n      font-weight: 900;\n      color: #1b4e9b;\n      margin: 0;\n      font-family: sans-serif;\n    }\n    p {\n      font-size: 30px;\n      font-weight: 500;\n      color: #000;\n      font-family: sans-serif;\n    }\n    button {\n      background-color: #bccf31;\n      border-radius: 5px;\n      border: none;\n      color: #fff;\n      padding-inline: 25px;\n      padding-block: 8px;\n      font-family: sans-serif;\n      font-size: 14px;\n      font-weight: 500;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pageContainer": `page_pageContainer__wdmvs`,
	"imgWrapper": `page_imgWrapper__87qQh`,
	"contentWrapper": `page_contentWrapper__JQgPE`
};
export default ___CSS_LOADER_EXPORT___;
